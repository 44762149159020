import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
    // {
    //     path: "/",
    //     component: () => import("../pages/MainPage.vue"),
    // },
    {
        // path: "/upload",
        path: "/",
        component: () => import("../pages/UploadPage.vue"),
    },
    {
      path: "/:downloadLink",
      component: () => import("../pages/DownloadPage.vue"),
    },
    {
        path: "/error",
        component: () => import("../pages/ErrorPage.vue"),
    },
    {
        path: "/:catchAll(.*)",
        redirect: "/error"
    },
];

export default new VueRouter({
    mode: "history",
    routes: routes,
});
